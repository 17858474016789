
//netlify
// export const baseURL = "http://localhost:5000"
// export const baseURL = "https://hranker.herokuapp.com"
// export const socketURL = "https://hranker.herokuapp.com"
// export const clientId = "924996333248-b18i1m98ji19j0tfl0emmiv9el52eh2u.apps.googleusercontent.com"

//hranker
export const baseURL = "https://community.hranker.com/admin"
export const clientId = "269551783097-vidavj5vrkrt20og6oug42u647aaavlh.apps.googleusercontent.com"
export const socketURL = "https://hranker.herokuapp.com"